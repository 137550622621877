<template>
  <div class="row mt-6">
    <div class="col-3">
      <!-- Google -->
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">
            Outlook Kalendar
          </h5>
          <p class="card-text">
            Verbinde deinen Outlook Kalender mit Bookingflix.
          </p>
          <div v-if="isDisabled">
            <p style="color: #e0d441">Zuerst den Bookingflix-Kalendar wählen</p>
          </div>
          <button v-else ref="authorizeButtonMs" @click="handleClickAction" type="button" class="flix-btn flix-btn-default">
            Einloggen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as msal from '@azure/msal-browser'

export default {
  name: 'msCalendarIntegration',
  mounted () {
    this.fetchConnectedCalendars()
  },
  data: () => ({
    isLoggedIn: false
  }),
  props: {
    isDisabled: {
      type: Boolean,
      required: true
    },
    selectedCalendarId: {
      type: String,
      required: true
    }
  },
  watch: {
    selectedCalendarId (newValue) {
      this.fetchConnectedCalendars()
    }
  },
  methods: {
    async startAuthorization () {
      const msalConfig = {
        auth: {
          clientId: process.env.VUE_APP_MS_CLIENT_ID,
          redirectUri: process.env.VUE_APP_URL
        }
      }

      const msalRequest = {
        scopes: [
          'user.read',
          'mailboxsettings.read',
          'calendars.read',
          'offline_access'
        ]
      }

      // Create the main MSAL instance
      const msalClient = new msal.PublicClientApplication(msalConfig)

      try {
        // Use MSAL to login
        const authResult = await msalClient.loginPopup(msalRequest)
        const tokenCache = await msalClient.getTokenCache()
        const windowStorage = tokenCache.storage.browserStorage.windowStorage

        let refreshToken
        for (const item in windowStorage) {
          if (item.toString().includes('refreshtoken')) {
            refreshToken = JSON.parse(windowStorage[item])
          }
        }

        await this.storeConnection(authResult, refreshToken.secret)
        this.fetchConnectedCalendars()
      } catch (error) {
        console.log(error)
        console.error(error)
      }
    },
    handleClickAction () {
      if (this.isLoggedIn) {
        this.handleSignoutClick()
      } else {
        this.startAuthorization()
      }
    },
    /**
     *  Called when the signed in status changes, to update the UI
     *  appropriately. After a sign-in, the API is called.
     */
    updateSigninStatus (isSignedIn, calendarName = '') {
      if (isSignedIn && calendarName) {
        this.isDisabled = false
        this.$refs.authorizeButtonMs.setAttribute('class', 'flix-btn flix-btn-success')
        this.$refs.authorizeButtonMs.innerHTML = 'Verknüpfung mit ' + calendarName + ' löschen'
      } else {
        this.$refs.authorizeButtonMs.setAttribute('class', 'flix-btn flix-btn-default')
        this.$refs.authorizeButtonMs.innerHTML = 'Einloggen'
      }
    },

    /**
     *  Sign in the user upon button click.
     */
    storeConnection (authResult, refreshToken) {
      this.$flix_post({
        url: 'external_calendar/microsoft365/create_connection',
        data: {
          user: this.$store.getters.user.md5_id,
          calendarId: this.selectedCalendarId,
          refreshToken: refreshToken,
          name: authResult.account.username,
          accessToken: authResult.accessToken,
          first_issued_at: authResult.idTokenClaims.iat,
          expires_in: authResult.idTokenClaims.exp
        },
        callback: function (ret) {
          this.fetchConnectedCalendars()
        }.bind(this)
      })
    },

    /**
     *  Sign out the user upon button click.
     */
    handleSignoutClick (event) {
      // eslint-disable-next-line no-undef
      this.$flix_post({
        url: 'external_calendar/microsoft365/delete',
        data: {
          user: this.$store.getters.user.md5_id,
          calendarId: this.selectedCalendarId
        },
        callback: function (ret) {
          this.fetchConnectedCalendars()
        }.bind(this)
      })
    },

    fetchConnectedCalendars () {
      this.$flix_post({
        url: 'external_calendar/microsoft365/get',
        data: {
          user: this.$store.getters.user.md5_id,
          calendarId: this.selectedCalendarId
        },
        callback: function (ret) {
          const calendarName = ret.data[1].calendar

          if (calendarName && calendarName.length > 1) {
            this.updateSigninStatus(true, calendarName)
            this.isLoggedIn = true
          } else {
            this.updateSigninStatus(false)
            this.isLoggedIn = false
          }
        }.bind(this)
      })
    }
  }
}
</script>

<style scoped>

</style>
